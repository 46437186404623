/**
 * ActionGroupMulti Component
 * @module views/modules/ActionGroupMulti
 */

import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import PaletteActionGroupMulti from 'web-palette/dist/components/ActionGroupMulti/ActionGroupMulti';
import * as paletteUtils from 'web-palette/dist/components/ActionGroupMulti/ActionGroupMulti';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';

import * as utils from './ActionGroupMulti';

/**
 * Represents a ActionGroupMulti component
 *
 * @method
 * @param { ActionGroupMultiProps } props
 * @returns ActionGroupMulti
 */

export const ActionGroupMulti = function( props ){
  const {
    // Array of actions
    actions,
    // Styles set in Amplience
    style,
    types,
    // Generated styles based coming from parent components
    alignment,
    isKnockoutLink
  } = props;

  if( !actions ){
    return null;
  }

  const hasOneAction = ( actions.length < 2 );

  /* Set the default link styles */
  let linkStyle = {
    variant: 'primary',
    size: 'compact'
  };

  /* Set the default button styles */
  let buttonStyle = {
    variant: 'secondary',
    size: 'tiny'
  };

  /* Set the array of actions to be mapped over */
  const actionGroups = actions?.map( ( action, index ) => (
    paletteUtils.actionFormatter( { linkStyle, buttonStyle, action: action, index: index, types: types, style: style, hasOneAction: hasOneAction, isKnockoutLink: isKnockoutLink }, { getButtonMarkup: utils.getButtonHtml, getLinkMarkup: utils.getLinkHtml } )
  ) );

  const PalActionGroupMulti = PaletteActionGroupMulti.default || PaletteActionGroupMulti;

  return (
    <PalActionGroupMulti
      alignment={ alignment }
      externalActions={ actionGroups }
      hasOneExternalAction={ hasOneAction }
      isKnockoutLink={ isKnockoutLink }
      types={ types }
      style={ style }
    />
  );
};

/**
 * Function for link action style
 * @function getLinkHtml
 * @param { object }  - returns an object with the appropriate HTML markup
 */
export const getLinkHtml = ( data ) => {
  const {
    action,
    hasOneAction,
    index,
    isKnockoutLink,
    linkStyle
  } = data;

  return (
    <Fragment key={ index } >
      { !hasOneAction &&
      <li
        className='pal-c-ActionGroupMulti--listItem'
        key={ index }
      >
        <Link_Huge
          variant={ linkStyle.variant }
          isKnockoutLink={ isKnockoutLink }
          size={ linkStyle.size }
          children={ action?.label }
          action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with Link_Huge. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
          { ...action }
        />
      </li>
      }
      { hasOneAction &&
        <Link_Huge
          variant={ linkStyle.variant }
          isKnockoutLink={ isKnockoutLink }
          size={ linkStyle.size }
          children={ action?.label }
          action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with Link_Huge. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
          { ...action }
        />
      }
    </Fragment>
  );
};

/**
 * Function for button action style
 * @function getButtonHtml
 * @param { object }  - returns an object with the appropriate HTML markup
 */
export const getButtonHtml = ( data ) => {
  const {
    action,
    buttonStyle,
    hasOneAction,
    index,
    isKnockoutLink
  } = data;

  return (
    <Fragment key={ index } >
      { !hasOneAction &&
        <li
          className='pal-c-ActionGroupMulti--listItem'
          key={ index }
        >
          <Button
            variant={ buttonStyle.variant }
            isKnockoutLink={ isKnockoutLink }
            size={ buttonStyle.size }
            action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with the web-site Button. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
            { ...action }
          />
        </li>
      }
      { hasOneAction &&
        <Button
          variant={ buttonStyle.variant }
          isKnockoutLink={ isKnockoutLink }
          size={ buttonStyle.size }
          action={ action } // We need to pass this specifically for the action to work with DC and everything else that comes with the web-site Button. Palette bundles this as one object and doesn't compose the action props the way web-site needs them. We did this this way to keep business logic out of Palette
          { ...action }
        />
      }
    </Fragment>
  );
};

/**
 * Property type definitions
 * @typedef ActionGroupMulti
 * @type {object}
 * @property {array} actions - allows passing an object to the actionGroup object
 * @property {string} alignment - sets the actions to centered
 * @property {string} buttonStyle - sets the button style for ActionGroupMulti
 * @property {boolean} isKnockoutLink - sets the link text to white
 * @property {string} linkStyle - sets the link style for ActionGroupMulti
 * @property {string} style - sets the style of the ActionGroups
 * @property {string} types - sets the types of the ActionGroups
 * @property {string} variant - sets the variant of the ActionGroups
 */

const propTypes = {
  /**
   * Allows passing an object to the actionGroup object
   */
  actions: PropTypes.array,
  /**
   * Sets the actions to centered
   */
  alignment: PropTypes.string,
  /**
   * sets the button style for ActionGroupMulti
   */
  buttonStyle: PropTypes.string,
  /**
   * Sets the link text to white
   */
  isKnockoutLink: PropTypes.bool,
  /**
   * sets the link style for ActionGroupMulti
   */
  linkStyle: PropTypes.string,
  /**
   * sets the style of the ActionGroups
   */
  style: PropTypes.string,
  /**
   * type component above the eyebrow
   */
  types: PropTypes.string,
  /**
   * sets the variant of the ActionGroups
   */
  variant: PropTypes.string
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} alignment - alignment: 'left' - sets the default for the headline tag
 */
export const defaultProps =  {
  alignment: 'left'
};

ActionGroupMulti.propTypes = propTypes;
ActionGroupMulti.defaultProps = defaultProps;

export default ActionGroupMulti;